<template>
    <div
        id="layout"
        v-shortkey="['ctrl', '/']"
        class="d-flex flex-grow-1"
        @shortkey="onKeyup"
    >
        <!-- Navigation -->
        <v-navigation-drawer
            v-model="drawer"
            app
            floating
            class="elevation-1"
            :right="$vuetify.rtl"
            :light="menuTheme === 'light'"
            :dark="menuTheme === 'dark'"
        >
            <!-- Navigation menu info -->
            <template v-slot:prepend>
                <div class="pa-2">
                    <div class="title font-weight-bold text-uppercase primary--text">
                        {{ product.name }}
                    </div>
                    <!-- <div class="overline grey--text">{{ product.version }}</div> -->
                </div>
            </template>

            <v-sheet color="grey lighten-4" class="pa-4">
                <v-skeleton-loader v-if="!account" type="avatar" />
                <div v-else class="d-flex flex-column">
                    <v-avatar size="64" class="elevation-3 mx-auto">
                        <v-img v-if="account.avatar" :src="account.avatar" />
                        <v-icon v-else dark size="64">mdi-account-circle</v-icon>
                    </v-avatar>
                    <div class="mx-auto">{{ account.name }}</div>
                </div>
            </v-sheet>

            <!-- Navigation menu -->
            <MainMenu :menu="navigation.menu" />

            <!-- 底部导航 -->
            <!-- <template v-slot:append>
                <div class="pa-1 text-center">
                    <v-btn
                        v-for="(item, index) in navigation.footer"
                        :key="index"
                        :href="item.href"
                        :target="item.target"
                        small
                        text
                    >
                        {{ item.key ? $t(item.key) : item.text }}
                    </v-btn>
                </div>
            </template> -->
        </v-navigation-drawer>

        <!-- Toolbar -->
        <v-app-bar
            app
            :color="isToolbarDetached ? 'surface' : undefined"
            :flat="isToolbarDetached"
            :light="toolbarTheme === 'light'"
            :dark="toolbarTheme === 'dark'"
        >
            <v-card
                class="flex-grow-1 d-flex"
                :class="[isToolbarDetached ? 'pa-1 mt-3 mx-1' : 'pa-0 ma-0']"
                :flat="!isToolbarDetached"
            >
                <div class="d-flex flex-grow-1 align-center">

                    <div class="d-flex flex-grow-1 align-center">
                        <v-app-bar-nav-icon @click.stop="drawer = !drawer" />

                        <v-spacer class="d-none d-lg-block" />

                        <!-- search input desktop (PC版搜索框) -->
                        <!-- <v-text-field
                            ref="search"
                            class="mx-1 hidden-xs-only"
                            :placeholder="$t('menu.search')"
                            prepend-inner-icon="mdi-magnify"
                            hide-details
                            filled
                            rounded
                            dense
                        /> -->

                        <v-spacer class="d-block d-sm-none" />

                        <ToolbarLanguage />

                        <!--
                        <div class="hidden-xs-only mx-1">
                            <toolbar-currency />
                        </div>
                        -->

                        <!-- <ToolbarApps />

                        <div :class="[$vuetify.rtl ? 'ml-1' : 'mr-1']">
                            <ToolbarNotifications />
                        </div>

                        <ToolbarUser /> -->
                    </div>
                </div>
            </v-card>
        </v-app-bar>

        <v-main>
            <v-container class="fill-height" :fluid="!isContentBoxed">
                <v-layout>
                    <router-view />
                </v-layout>
            </v-container>

            <v-footer app inset>
                <v-spacer></v-spacer>
                <div class="overline">
                    ©{{ copyrightYears }} <a target="_blank" href="//stcore.cn">store.cn</a>
                </div>
            </v-footer>
        </v-main>
    </div>
</template>

<script>
import { mapState } from 'vuex';

// navigation menu configurations
import config from '@/configs';
import MainMenu from './navigation/MainMenu';
import ToolbarLanguage from './toolbar/ToolbarLanguage';
// import ToolbarUser from './toolbar/ToolbarUser';
// import ToolbarApps from './toolbar/ToolbarApps';
// import ToolbarNotifications from './toolbar/ToolbarNotifications';

export default {
    components: {
        MainMenu,
        ToolbarLanguage
        // ToolbarApps,
        // ToolbarNotifications,
        // ToolbarUser
    },
    data () {
        return {
            drawer: null,
            showSearch: false,

            navigation: config.navigation,

            siteProfile: null,
            account: null
        };
    },
    computed: {
        ...mapState('app', [
            'product',
            'isContentBoxed',
            'menuTheme',
            'toolbarTheme',
            'isToolbarDetached'
        ]),
        copyrightYears () {
            if (this.siteProfile) {
                return this.siteProfile.copyrightStartYear === this.siteProfile.copyrightEndYear
                    ? this.siteProfile.copyrightStartYear
                    : `${this.siteProfile.copyrightStartYear}-${this.siteProfile.copyrightEndYear}`;
            } else {
                return null;
            }
        }
    },
    methods: {
        onKeyup (e) {
            this.$refs.search.focus();
        }
    },
    async created () {
        this.siteProfile = await this.$store.dispatch('app/getSiteProfile');
        this.account = await this.$store.dispatch('session/getAccountInfo');
        this.setDocumentTitle(this.siteProfile.name);
        this.setTheme(this.siteProfile.theme);
    }
};
</script>
