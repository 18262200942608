<template>
    <v-list nav dense>
        <div v-for="(item) in menu" :key="item.key">
            <div v-if="item.key || item.text" class="pa-1 mt-2 overline">
                {{ item.key ? $t(item.key) : item.text }}
            </div>
            <NavMenu :menu="item.items" />
        </div>
    </v-list>
</template>

<script>
import NavMenu from './NavMenu';

export default {
    components: {
        NavMenu
    },
    props: {
        menu: {
            type: Array,
            default: () => []
        }
    }
};
</script>
